export function useLockBackground(open: MaybeRefOrGetter<boolean>) {
  // Fix for iOS Safari
  // https://bugs.webkit.org/show_bug.cgi?id=153852#c43
  const lockBackground = (menuOpen: boolean) => {
    document.querySelector("#teleported")?.classList.toggle("h-full", menuOpen);

    const classList = ["inset-x-0", "fixed", "overflow-hidden"];

    if (menuOpen) {
      const offsetY = window.scrollY;
      document.body.style.top = `${-offsetY}px`;
      document.body.classList.add(...classList);
    } else {
      const offsetY = Math.abs(parseInt(document.body.style.top || "0", 10));
      document.body.classList.remove(...classList);
      document.body.style.removeProperty("top");
      // not implemented in JSDOM
      window.scrollTo(0, offsetY || 0);
    }
  };

  watchEffect(() => {
    if (!import.meta.env.SSR) {
      lockBackground(toValue(open));
    }
  });
}
